@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&family=JetBrains+Mono:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --accent-blue: #63B3ED;
  --dark-bg: #0F1620;
  --grid-blue: rgba(44, 82, 130, 0.07);
  --text-primary: #ffffff;
  --text-secondary: #63B3ED;
}

@keyframes twinkle {
  0%, 100% { opacity: 0.6; }
  50% { opacity: 1; }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes scan {
  0% { transform: translateY(-100%); }
  100% { transform: translateY(200%); }
}

@keyframes glitch {
  0% { transform: translateX(0) skewX(0deg); }
  20% { transform: translateX(-2px) skewX(2deg); }
  40% { transform: translateX(2px) skewX(-2deg); }
  60% { transform: translateX(-1px) skewX(1deg); }
  80% { transform: translateX(1px) skewX(-1deg); }
  100% { transform: translateX(0) skewX(0deg); }
}

@keyframes pulse {
  0%, 100% { opacity: 0.3; }
  50% { opacity: 0.6; }
}

@keyframes holographic {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes float-slow {
  0%, 100% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(-10px) rotate(1deg); }
}

body {
  margin: 0;
  font-family: 'Space Grotesk', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--dark-bg);
  background-image:
    linear-gradient(to right, var(--grid-blue) 1px, transparent 1px),
    linear-gradient(to bottom, var(--grid-blue) 1px, transparent 1px);
  background-size: 40px 40px;
}

.mono {
  font-family: 'JetBrains Mono', monospace;
}

.glass-panel {
  background: rgba(19, 30, 43, 0.8);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(99, 179, 237, 0.1);
}

.card-hover {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.card-hover:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 20px rgba(99, 179, 237, 0.1);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Space Grotesk', sans-serif;
}

.text-gradient {
  background: linear-gradient(to right, var(--accent-blue), #90CDF4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-star-pattern {
  background-image: radial-gradient(circle at center, var(--accent-blue) 0.1%, transparent 0.5%);
  background-size: 16px 16px;
}

/* TipTap Editor Styles */
.ProseMirror {
  font-family: 'Space Grotesk', sans-serif !important;
  color: var(--text-primary);
  min-height: 500px;
  padding: 1rem;
}

.ProseMirror p {
  margin: 1em 0;
  color: var(--text-primary);
}

.ProseMirror:focus {
  outline: none;
}

/* Headings */
.ProseMirror h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  margin: 1.5em 0 0.5em;
  color: var(--text-primary);
}

.ProseMirror h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  margin: 1.5em 0 0.5em;
  color: var(--text-primary);
}

/* Lists */
.ProseMirror ol {
  list-style-type: decimal;
  color: var(--text-primary);
  padding-left: 1.5em;
  margin: 1em 0;
}

.ProseMirror ul {
  list-style-type: disc;
  color: var(--text-primary);
  padding-left: 1.5em;
  margin: 1em 0;
}

.ProseMirror li {
  margin: 0.5em 0;
  color: var(--text-primary);
}

.ProseMirror li::marker {
  color: var(--text-primary);
}

.ProseMirror li p {
  margin: 0;
  color: var(--text-primary);
}

/* Code blocks */
.ProseMirror pre {
  background: var(--dark-bg);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1em 0;
  font-family: 'JetBrains Mono', monospace;
  color: var(--text-primary);
}

.ProseMirror code {
  background: var(--dark-bg);
  padding: 0.2em 0.4em;
  border-radius: 0.25rem;
  font-family: 'JetBrains Mono', monospace;
  font-size: 0.875em;
  color: var(--text-primary);
}

/* Blockquotes */
.ProseMirror blockquote {
  border-left: 4px solid var(--accent-blue);
  padding-left: 1rem;
  margin: 1em 0;
  font-style: italic;
  color: var(--text-primary);
}

/* Links */
.ProseMirror a {
  color: var(--accent-blue);
  text-decoration: none;
  transition: color 0.2s ease;
}

.ProseMirror a:hover {
  text-decoration: underline;
}

/* Images */
.ProseMirror img {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
  margin: 1em 0;
}

/* Selection */
.ProseMirror ::selection {
  background: var(--accent-blue);
  color: white;
}

/* Placeholder text */
.ProseMirror p.is-editor-empty:first-child::before {
  color: #4A5568;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

/* Nested lists */
.ProseMirror ol ol,
.ProseMirror ul ul,
.ProseMirror ul ol,
.ProseMirror ol ul {
  margin: 0.5em 0;
}

/* Ensure all list markers are visible */
.ProseMirror ul li::before,
.ProseMirror ol li::before {
  color: var(--text-primary);
}

/* Basic text elements */
.ProseMirror strong {
  color: var(--text-primary);
  font-weight: 600;
}

.ProseMirror em {
  color: var(--text-primary);
  font-style: italic;
}